<template>
  <div class="select-container row mx-1 mb-4">
    <div class="w-75">
      <b-form-select v-model="selected_student_id" value-field="id" size="sm">
        <b-form-select-option :value="null"
          >Seleccionar Estudiante</b-form-select-option
        >
        <b-form-select-option
          v-for="student in students"
          :key="student.id"
          :value="student.id"
        >
          {{ student.first_name + " " + student.last_name }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <div class="navigate-items w-25">
      <template v-if="students.length > 0">
        <b-button
          size="sm"
          variant="none"
          @click="previousUser"
          class="navigate-left"
          :class="{ 'navigate-left-sticky': sticky }"
        >
          <b-icon icon="chevron-left"></b-icon>
        </b-button>
        <b-button
          size="sm"
          variant="none"
          @click="nextUser"
          class="navigate-right"
          :class="{ 'navigate-right-sticky': sticky }"
        >
          <b-icon icon="chevron-right"></b-icon>
        </b-button>
      </template>
      <template v-else
        ><b-badge class="badge-no-student ml-2 mr-2" variant="danger"
          >No hay estudiantes seleccionados.</b-badge
        ></template
      >
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudentCarousel",
  props: {
    section_id: {
      type: Number,
    },
    current_users: {
      type: Array,
    },
    sticky: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected_student_id: null,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      users: names.USERS,
    }),
    section() {
      if (!this.section_id) return [];
      return this.sections.find((x) => x.id == this.section_id);
    },
    students() {
      let list = [];
      if (!this.section_id) {
        if (this.current_users) {
          list = this.users.filter((x) => this.current_users.includes(x.id));
        }
      } else {
        if (!this.section) return list;
        this.section.students.forEach((student_id) => {
          const student = this.users.find((x) => x.id == student_id);
          if (student) list.push(student);
        });
      }
      return list;
    },
  },
  methods: {
    previousUser() {
      if (this.students.length == 0) return;
      if (!this.selected_student_id) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index == 0) {
        this.selected_student_id = this.students[this.students.length - 1].id;
        return;
      } else if (this.students.length > 0) {
        this.selected_student_id = this.students[index - 1].id;
        return;
      }
    },
    nextUser() {
      if (this.students.length == 0) return;
      if (!this.selected_student_id && this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
      const index = this.students.findIndex(
        (x) => x.id == this.selected_student_id
      );
      if (index != -1 && index < this.students.length - 1) {
        this.selected_student_id = this.students[index + 1].id;
        return;
      } else if (this.students.length > 0) {
        this.selected_student_id = this.students[0].id;
        return;
      }
    },
  },
  watch: {
    selected_student_id() {
      this.$emit("student_selcted", this.selected_student_id);
    },
  },
  created() {
    if (this.section_id) {
      this.$store
        .dispatch(names.FETCH_SECTION, this.section_id)
        .then((section) => {
          // this.selected_student_id = section.students[0];
          this.$store.dispatch(names.FETCH_ALT_USERS, {
            user_ids: section.students,
          });
        });
    }
    if (this.current_users) {
      // this.selected_student_id = this.current_users[0];
      this.$store.dispatch(names.FETCH_ALT_USERS, {
        user_ids: this.current_users,
      });
    }
  },
};
</script>

<style scoped>
.badge-no-student {
  display: flex;
  align-items: center;
}
.select-container {
  width: 100%;
}
.navigate-left,
.navigate-left-sticky,
.navigate-right,
.navigate-right-sticky {
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
  padding-inline: 1.5em;
}
.navigate-items {
  display: flex;
}
.navigate-left:hover,
.navigate-left-sticky:hover,
.navigate-right:hover,
.navigate-right-sticky:hover {
  background: var(--kl-primary-button-hover-color) !important;
}

.navigate-left {
  margin-left: 1rem;
}

.navigate-left,
.navigate-right {
  width: 100%;
}
.navigate-left {
  margin-right: 0.5em;
}
.navigate-right {
  margin-left: 0.5em;
}

.navigate-left-sticky,
.navigate-right-sticky {
  width: 30%;
}
@media (max-width: 991px) {
  .student-carousel-sticky > .select-container {
    width: 80%;
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
}
</style>